.select-nav {
  position: sticky;
  top: 100px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

input {
  display: flex;
  width: 100%;
}

.detail-part {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 50px;

  .question-content {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;

    .question-box {
      display: flex;
      width: 100%;
      flex-direction: column;

      .option-list {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;

        .option-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          background-color: white;
          border: 1px solid #c2c2c2;
          border-radius: 8px;
          cursor: pointer;
        }

        .item {
          display: flex;
          width: 100%;
          gap: 10px;
          align-items: center;

          .remove-btn-sm {
            @extend .option-btn;
            min-width: 80px;
          }
        }
      }
    }

    .remove-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      background-color: white;
      border: 1px solid #c2c2c2;
      border-radius: 8px;
      cursor: pointer;
      min-width: 120px;
    }
  }
}
