.err_msg {
    margin-left: 10px;
    font-size: 14px;
    color: red;
    opacity: 0;
    animation: 4s ease-out 0s fade_in_out;
}

@keyframes fade_in_out {
    35% {
        opacity: 1;
    }
    100% {
        display: none;
    }
}