.editor-img-100 {
  .image-inline {
    display: block !important;
    width: 100% !important;

    img {
      width: 100% !important;
    }
  }
}
