/**=====================
    21. According CSS start
==========================**/
.default-according {
  .card {
    margin-bottom: 0 !important;
    & + .card {
      margin-top: $according-card-top-margin;
    }
    .btn-link {
      font-weight: $according-btn-weight;
      color: $theme-body-font-color;
      font-family: $font-nunito, $font-serif;
      &:focus,
      &:hover {
        text-decoration: none;
        background-color: $transparent-color !important;
        border-color: $transparent-color !important;
      }
    }
    .card-header {
      padding: $according-card-header-padding;
      i {
        position: absolute;
        left: 18px;
        font-size: 20px;
        top: 20px;
      }
      h5 {
        margin-top: 2px;
      }
    }
  }
}
.default-according.style-1 {
  button {
    width: 100%;
    text-align: left;
    &:before {
      right: 20px;
      position: absolute;
      transition: 0.4s;
    }
    &[aria-expanded="true"] {
      &:before {
        content: $according-open-icon;
        font-family: $according-card-header-icon;
      }
    }
    &[aria-expanded="false"] {
      &:before {
        content: $according-close-icon;
        font-family: $according-card-header-icon;
      }
    }
  }
}
/**=====================
     21. According CSS Ends
==========================**/
