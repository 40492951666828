@font-face {
  font-family: "NotoSansCJKkr-Medium";
  src: url('https://s3.ap-northeast-2.amazonaws.com/dev-img.bear-u.com/static/font/NotoSansCJKkr-Medium.otf');
}

.table-sort-th {
  cursor: pointer;
}

.table-sort-th.active {
  color: #ff4c3b !important;
}

.table-sort-th:hover {
  color: #ff4c3b !important;
}

tbody > tr:hover {
  background: #eeeeee;
}

.invoice-table tbody > tr:hover {
  background: none;
}

.autocomplete-wrapper {
  position: relative;
}

.autocomplete-wrapper > div {
  width: 100%;
}

.autocomplete-wrapper input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
  /*transition: border-color 0.15s ease-in-out*/
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15);
}

.autocomplete-wrapper input:focus {
  border-color: #7e37d8;
  box-shadow: none;
  outline: none;
}

.autocomplete-wrapper .dropdown {
  width: 100%;
  text-align: left;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-top: none;
  position: absolute;
  z-index: 2;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #7e37d8;
  color: #FAFBFC;
}

.autocomplete-wrapper .item:hover {
  background-color: #7e37d8;
  color: #FAFBFC;
}

.input-tag {
  background: white;
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0;
  border: 1px solid #efefef;
}

.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__tags li {
  align-items: center;
  background: rgba(126, 55, 216, 0.1);
  border-radius: 0.25rem;
  color: #7e37d8;
  display: flex;
  font-size: 0.75rem;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
}

.input-tag__tags li button {
  align-items: center;
  appearance: none;
  background: rgba(126, 55, 216, 0.1) !important;
  border: none;
  border-radius: 50%;
  color: #7e37d8;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
  background: none;
  flex-grow: 1;
  padding: 0;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}


.ck.ck-content ol,
.ck.ck-content ol li {
  display: list-item;
}

.ck.ck-content ol {
  padding-left: 40px;
}

.ck.ck-content {
  height: 450px;
}

.tooltip-inner {
  text-align: left;
  max-width: none;
  white-space: pre-wrap;
}

.swal2-html-container {
  white-space: pre-wrap;
}

.multiple-custom-typeahead {
  height: calc(1.5em);
}

.elem_cert_content div {
  margin-bottom: 10px;
}

.elem_cert_content .key {
  display: inline-block;
  width: 20%;
}

.elem_cert_content .value {
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid #d0d0d0;
  padding-left: 10px;
  white-space: pre;
}

.elem_cert_content .value:focus {
  outline: 1px solid #d0d0d0;
}

.elem_cert_content .remove {
  position: absolute;
  left: -50px;
}

.dzu-inputLabel {
  white-space: pre;
}

.elem_cert_content .remove {
  position: absolute;
  left: -50px;
}

.subject-td td {
  padding: 0.5rem 0.1rem 0.5rem !important;
}

.react-bootstrap-table-editing-cell .ck.ck-content {
  height: 70px;
}

.input-val-hover-evt:hover {
  font-weight: 600;
}

.typcn.typcn-arrow-forward:before {
  transform: scaleY(-1);
  vertical-align: bottom;
}

.invoice-closed-field-name {
  font-size: 14px;
  font-weight: 550;
  //margin-left: -5%;
}

.invoice-field-name {
  font-size: 14px;
  font-weight: 550;
  //margin-left: -10%;
}