.complete_text {
    margin-left: 10px;
    font-size: 14px;
    color: #7e37d8;
    opacity: 0;
    animation: 1s ease-in-out 0s fade_in_out;
}

@keyframes fade_in_out {
    35% {
        opacity: 1;
    }
}